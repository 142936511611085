<template>
  <sensitivity-template
    :sensitive-skin="sensitiveSkin"
    :field-errors="fieldErrors"
    @field-change="onFieldChange"
  />
</template>

<script>
import { makeStep, resetStoreValues } from '@/modules/questionnaire/mixins';
import { fieldBuilder } from '@/modules/questionnaire/mixins/makeStep';

import SensitivityTemplate from '@/modules/questionnaire/components/steps/questionnaire/skin-redness/sensitivity/SensitivityTemplate';

import { no } from '@/modules/questionnaire/api/helpers';

const fieldsToReset = ['inflammationDiscomfort'];

const { requiredField, field } = fieldBuilder;

const FIELDS = [...fieldsToReset.map(field), requiredField('sensitiveSkin')];

export default {
  name: 'Sensitivity',
  components: {
    SensitivityTemplate
  },
  mixins: [makeStep(FIELDS), resetStoreValues],
  watch: {
    sensitiveSkin(newValue) {
      if (no(newValue)) {
        this.resetStoreFieldsByName(fieldsToReset);
      }

      this.showNextStep();
    }
  }
};
</script>
